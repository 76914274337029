import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik, Form } from 'formik'
import { useRequest } from 'redux-query-react'
import {
  MultiFieldLine,
  MultiSelectField,
  SelectField,
  TextField,
  CreatableInputOnly,
  ParagraphTextField,
} from './FormFields'
import { Button, InvertedButton } from './Button'
import { validateMetadata } from '../utils/validators'
import { useSaveRecord } from '../hooks/messagesAndRequests'
import { metadataFieldInfo, metadataInit } from '../constants/metadataFields'
import { useHistory } from 'react-router-dom'
import { GREY_DARK, MAIN_CONTENT_WIDTH } from '../constants/cssVars'
import {
  countyOptions,
  rbfsRegionsOptions,
  cityOptions,
  modelTypeOptions,
} from '../constants/formOptions'
import { Footer } from './Common'
import { useSelectedRecord } from '../hooks/useSelectedRecord'

import { capitalizeFirstLetter } from '../utils/helpers'
import { useDispatch } from 'react-redux'

import { useConvertedContactFields } from './Contacts'

import { getRecordQuery } from '../actions/queries'
import { toast } from 'react-toastify'
import { WaveLoader } from './Loaders'
import { useCurrentUser } from '../hooks/useCurrentUser'
// import { submitGeodbFile } from '../utils/geodbUpload';
import { BackButton, BackIcon } from './Settings'

const Content = styled.div`
  width: ${MAIN_CONTENT_WIDTH}px;
  @media only screen and (max-width: ${MAIN_CONTENT_WIDTH}px) {
    width: 100%;
  }
`

const IntroText = styled.p`
  color: ${GREY_DARK};
  font-size: 0.95em;
`

const InfoMultiTextInputCreateOnly = (props) => {
  return (
    <CreatableInputOnly
      {...props}
      label={`${getFriendlyName(props.name)}${
        getRequired(props.name) ? '*' : ''
      }`}
      info={getDescription(props.name)}
    />
  )
}

const InfoTextField = (props) => {
  return (
    <TextField
      {...props}
      label={`${getFriendlyName(props.name)}${
        getRequired(props.name) ? '*' : ''
      }`}
      info={getDescription(props.name)}
    />
  )
}

const InfoParagraphTextField = (props) => {
  return (
    <ParagraphTextField
      {...props}
      label={`${getFriendlyName(props.name)}${
        getRequired(props.name) ? '*' : ''
      }`}
      info={getDescription(props.name)}
    />
  )
}

const InfoSelectField = (props) => {
  return (
    <SelectField
      {...props}
      label={`${getFriendlyName(props.name)}${
        getRequired(props.name) ? '*' : ''
      }`}
      info={getDescription(props.name)}
    />
  )
}

export const getContactName = (name) => {
  if (name.includes('contacts.')) {
    const contactArray = name.split('.')
    const contactString = `contact${capitalizeFirstLetter(contactArray[2])}`
    return contactString
  }
  return name
}

const InfoMultiSelectField = (props) => {
  return (
    <MultiSelectField
      {...props}
      label={`${getFriendlyName(props.name)}${
        getRequired(props.name) ? '*' : ''
      }`}
      info={getDescription(props.name)}
    />
  )
}

const getDescription = (name) =>
  metadataFieldInfo[getContactName(name)].description
const getFriendlyName = (name) =>
  metadataFieldInfo[getContactName(name)].friendlyName
const getRequired = (name) => metadataFieldInfo[getContactName(name)].required

export const MetadataForm = () => {
  const history = useHistory()
  const [selectedRecord] = useSelectedRecord()
  return (
    <Content>
      <BackButton
        style={{ paddingTop: '20px' }}
        onClick={() => history.push('/app')}
        ariaLabel="back"
      >
        <BackIcon alt="back arrow" />
      </BackButton>
      <h2 style={{ marginTop: '10px' }}>
        {selectedRecord ? 'Edit' : 'Create new'} metadata record
      </h2>
      <IntroText>
        This metadata record should refer to a <b>single</b> model. We will use
        this metadata record to generate a metadata YAML file that will be
        included in your model's upload.
      </IntroText>
      <h3>Basic</h3>
      <MetadataFormContent />
    </Content>
  )
}

const MetadataFormContent = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [selectedRecord, recordId] = useSelectedRecord()
  const [saveObj, saveSubmitting] = useSaveRecord(recordId)
  // For saving via "Generate Metadata", we want to show loading on the "Generate Metadata" button & hide the toast notification
  const [saveObjViaMetadataClick, saveObjViaMetadataClickSubmitting] =
    useSaveRecord(recordId, true)
  const { accessToken } = useCurrentUser()
  const [{ isFinished: getRequestFinished }] = useRequest(
    accessToken && recordId !== 'new'
      ? getRecordQuery(recordId, accessToken)
      : null
  )
  const [pressedGenerate, setPressedGenerate] = useState(false)
  const initValues = selectedRecord
    ? { ...metadataInit, ...selectedRecord.currentMetadata }
    : metadataInit

  if (typeof initValues.county === 'string') {
    initValues.county = initValues.county.split(',').map((item) => item.trim())
  }

  if (typeof initValues.city === 'string') {
    initValues.city = initValues.city.split(',').map((item) => item.trim())
  }
  if (Array.isArray(initValues.modelInput)) {
    initValues.modelInput = initValues.modelInput.join(', ')
  }
  if (Array.isArray(initValues.modelOutput)) {
    initValues.modelOutput = initValues.modelOutput.join(', ')
  }

  const dbContactsField = useConvertedContactFields(recordId)

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  // const onFileChange = (e) => {
  //   submitGeodbFile(e.target.files[0], e.target.files[0].name, regionId || initValues.twdbFloodPlanningRegionID, accessToken);
  // }

  const noErrors = (errors) => {
    if (Object.keys(errors).length === 0) {
      return true
    }
    const totalErrors = Object.keys(errors).length
    toast.error(`Please fix ${totalErrors} error${totalErrors > 1 ? 's' : ''}.`)
    return false
  }

  const handleGenerateMetadataClick = (values, errors) => {
    if (noErrors(errors)) {
      saveObjViaMetadataClick(
        { ...values, contacts: dbContactsField },
        (newId) =>
          history.push(`/app/instructions/${selectedRecord ? recordId : newId}`)
      )
    }
  }

  if (recordId !== 'new' && !getRequestFinished) {
    return <WaveLoader />
  }

  return (
    <Formik
      validate={validateMetadata(metadataFieldInfo)}
      enableReinitialize={true}
      onSubmit={() => null}
      initialValues={{
        ...initValues,
      }}
    >
      {({ errors, touched, submitCount, values, setFieldValue }) => {
        // return error if field is touched
        const getError = (name) =>
          (touched[name] || submitCount >= 1 || pressedGenerate) && errors[name]

        return (
          <Form onKeyDown={onKeyDown}>
            <MultiFieldLine columns="50% 50%">
              <InfoSelectField
                name="rbfsRegionID"
                options={rbfsRegionsOptions}
                error={getError('rbfsRegionID')}
              />
              <InfoTextField
                name="hydrologicUnitCode"
                error={getError('hydrologicUnitCode')}
              />
            </MultiFieldLine>

            <MultiFieldLine columns="50% 50%">
              <InfoMultiSelectField
                name="county"
                options={countyOptions}
                error={getError('county')}
              />
              <InfoMultiSelectField
                name="city"
                options={cityOptions}
                error={getError('city')}
              />
            </MultiFieldLine>

            <MultiFieldLine columns="50% 50%">
              <InfoTextField name="modelName" error={getError('modelName')} />
              <InfoTextField name="creator" error={getError('creator')} />
            </MultiFieldLine>
            <MultiFieldLine columns="100%">
              <InfoParagraphTextField
                name="modelDescription"
                error={getError('modelDescription')}
              />
            </MultiFieldLine>
            <MultiFieldLine columns="100%">
              <InfoParagraphTextField
                name="modelPurpose"
                error={getError('modelPurpose')}
              />
            </MultiFieldLine>
            <MultiFieldLine columns="100%">
              <InfoParagraphTextField
                name="kewyWordTerm"
                error={getError('kewyWordTerm')}
              />
            </MultiFieldLine>
            <MultiFieldLine columns="50% 50%">
              <InfoTextField
                name="updateFrequency"
                error={getError('updateFrequency')}
              />
              <InfoTextField
                name="useConstraint"
                error={getError('useConstraint')}
              />
            </MultiFieldLine>

            <h3>Model details</h3>
            <MultiFieldLine columns="50% 50%">
              <InfoSelectField
                name="modelType"
                options={modelTypeOptions}
                error={getError('modelType')}
              />
            </MultiFieldLine>

            <MultiFieldLine columns="100%">
              <InfoMultiTextInputCreateOnly
                name="modelInput"
                error={getError('modelInput')}
              />
            </MultiFieldLine>
            <MultiFieldLine columns="100%">
              <InfoMultiTextInputCreateOnly
                name="modelOutput"
                error={getError('modelOutput')}
              />
            </MultiFieldLine>
            <MultiFieldLine columns="50% 50%">
              <InfoTextField
                name="softwareName"
                error={getError('softwareName')}
              />
              <InfoTextField
                name="softwareVersion"
                error={getError('softwareVersion')}
              />
            </MultiFieldLine>

            <h3 style={{ marginTop: '40px' }}>Model Contacts</h3>
            <p
              style={{
                fontSize: '0.95em',
                color: GREY_DARK,
                margin: '0px 0px 20px 0px',
              }}
            >
              The following contact fields will be used as the primary point(s)
              of contact for this model. At least one contact is required.
            </p>

            <MultiFieldLine columns="50% 50%">
              <InfoTextField
                name="contactName"
                error={getError('contactName')}
              />
              <InfoTextField
                name="contactAffiliation"
                error={getError('contactAffiliation')}
              />
            </MultiFieldLine>

            <MultiFieldLine columns="50% 50%">
              <InfoTextField
                name="contactEmail"
                error={getError('contactEmail')}
              />
              <InfoTextField
                name="contactPhoneNumber"
                error={getError('contactPhoneNumber')}
              />
            </MultiFieldLine>
            <MultiFieldLine columns="50% 50%">
              <InfoTextField
                name="contactDepartment"
                error={getError('contactDepartment')}
              />
            </MultiFieldLine>
            <h3 style={{ marginTop: '40px' }}>Spatial Information</h3>
            <MultiFieldLine columns="50% 50%">
              <InfoTextField
                name="spatialDataType"
                error={getError('spatialDataType')}
              />
              <InfoTextField
                name="spatialExtentDecimalDegrees"
                error={getError('spatialExtentDecimalDegrees')}
              />
            </MultiFieldLine>

            <MultiFieldLine columns="50% 50%">
              <InfoTextField
                name="boundaryName"
                error={getError('boundaryName')}
              />
              <InfoTextField
                name="horizantalCoordinateSystem"
                error={getError('horizantalCoordinateSystem')}
              />
            </MultiFieldLine>
            <div style={{ width: '100%', height: '120px' }}></div>
            <Footer>
              <div>
                <div>
                  <InvertedButton onClick={() => history.push('/app/')}>
                    Back
                  </InvertedButton>
                  <div>
                    <Button
                      short={true}
                      type="button"
                      isLoading={saveObjViaMetadataClickSubmitting}
                      onClick={() => {
                        !pressedGenerate && setPressedGenerate(true)
                        handleGenerateMetadataClick(values, errors)
                      }}
                    >
                      Save & Proceed to Upload
                    </Button>
                  </div>
                </div>
              </div>
            </Footer>
          </Form>
        )
      }}
    </Formik>
  )
}
